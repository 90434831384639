
/* FONTS */

.onest-regular {
    font-family: "Onest", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.onest-light {
    font-family: "Onest", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

.stix-two-text-bold {
    font-family: "STIX Two Text", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

.inter-bold {
    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
}

.inter-regular {
    font-family: "Inter", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.montserrat-medium {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.montserrat-light {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}

/* ANIMATION */

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/*MAIN PART*/

body {
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera, and Firefox */
}

body, html {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
}

html {
    scroll-padding-bottom: 200px;
    scroll-behavior: auto;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.main-bg {
    background: url('../public/assets/banners/bg-pc.png') no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.avatar-container::after {
    content: "";
    display: block;
    clear: both;
}

.avatar-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar-upper {
    align-items: flex-end;
}

td .avatar-upper {
    align-items: normal;
}

.avatar {
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}

    .avatar.active {
        border: 1px solid darkgreen;
    }

    .avatar.inactive {
        border: 1px solid red;
    }

    .avatar.avatar-large {
        width: 100px;
        height: 100px;
    }

    .avatar.avatar-small {
        width: 25px;
        height: 25px;
    }

.text {
    margin: 0;
}

.user-info {
    flex: 1; /* ����������� �� ��� ��������� ������ */
}

.text-container {
    margin-bottom: 20px;
}

.menu-item {
    font-size: 18px;
    cursor: pointer;
}

    .menu-item ul {
        list-style-type: none;
        padding: 0;
    }

    .menu-item li {
        margin-bottom: 20px;
        text-decoration: none;
    }

        .menu-item li.active {
            text-decoration: underline;
        }

.horizontal-separator {
    border: 0;
    height: 2px;
    background-color: #dddeee;
    margin: 10px 0 10px 0;
}

.unread-counter {
    width: 20px;
    height: 20px;
    border-radius: 50%; /* This makes the shape a circle */
    background-color: red; /* Change color as needed */
    text-align: center;
    color: white; /* Color of the number */
    font-size: 14px; /* Adjust font size as needed */
    font-weight: bold;
    display: inline-block;
    margin-left: 5px;
}

.level, .level.exclusive {
    width: 17px;
    height: 17px;
    border-radius: 50%; /* This makes the shape a circle */
    background-color: #ead53d; /* Change color as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Color of the number */
    font-size: 10px; /* Adjust font size as needed */
    font-weight: bold;
    position: absolute;
    bottom: 0;
    right: 0;
}

    .level.admin {
        background-color: #c59100;
    }

    .level.basic {
        background-color: #007bff;
    }

    .level.standard {
        background-color: #c751fa;
    }

    .level.stdplus {
        background-color: #8d9d2b;
    }

    .level.bus {
        background-color: saddlebrown;
    }

    .level.busplus {
        background-color: black;
    }

.name, name.exclusive {
    font-weight: bold;
    font-size: 13px;
    color: #ead53d;
    display: flex;
}

    .name.admin {
        margin-top: 3px;
    }

        .name.admin span {
            border: 2px solid #c59100;
            border-radius: 5px;
            color: #c59100;
            padding-bottom: 1px;
            padding-left: 5px;
            padding-right: 5px;
        }

    .name.basic {
        color: #007bff;
    }

    .name.standard {
        color: #c751fa;
    }

    .name.stdplus {
        color: #8d9d2b;
    }

    .name.bus {
        color: saddlebrown;
    }

    .name.busplus {
        color: black;
    }

.name-reply {
    margin-top: 3px;
}

.award {
    color: gold !important;
    font-size: 16px;
}

.card-body.unread {
    background-color: rgba(207, 223, 255, 0.8);
}

.card-body .posted-at {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #555;
    font-size: 14px;
}

.card-title.card-title-custom {
    margin-top: 0.25rem;
    font-weight: 500;
    font-size: 20px;
}

.card {
    margin-right: 10%;
}

.card-text {
    margin-bottom: 15px;
    line-height: 22px;
}

.card-mine {
    background: #e2ffc7 !important;
    margin-right: 5px !important;
    margin-left: 10%;
}

.avatar-text {
    margin-top: 0.2rem;
    font-size: 16px;
    font-style: italic;
    color: #555;
}

.stream-countdown {
    font-style: italic;
    color: #444;
}

.content-holder {
    margin-left: 0;
    overflow: hidden;
}

.info-icon {
    position: absolute;
    cursor: pointer;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #fff;
    color: black;
    border: 1px solid black;
    border-radius: 50%;
    text-align: center;
    line-height: 12px; /* Centers the icon vertically */
    font-size: 10px; /* Adjusts the size of the icon */
}

    /* Optional: Ensures the icon stays centered even if font size changes */
    .info-icon i {
        vertical-align: middle;
    }

.tooltip-wrapper {
    position: relative;
    display: inline-block;
}

.tooltip-box {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
    padding: 6px 12px;
    color: white;
    background-color: black;
    border-radius: 4px;
    z-index: 1000;
    white-space: nowrap;
}

.btn-info {
    background-color: saddlebrown !important;
    border-color: saddlebrown !important;
}

    .btn-info:disabled {
        color: #fff !important;
    }

.btn-dark {
    background-color: #000 !important;
    border-color: #000 !important;
}

    .btn-dark:disabled {
        color: #fff !important;
    }

.corner {
    position: absolute;
    font-size: 12px;
    bottom: 25px;
    right: 10px;
    text-align: right;
}

.corner-negative {
    position: absolute;
    font-size: 12px;
    top: 100px;
    right: 50px;
}

    .corner-negative button {
        display: block;
        margin-top: 5px;
    }

.view3-toolbar {
    display: flex;
    width: 680px;
}

    .view3-toolbar div {
        margin: 10px;
    }

.comment-input {
    width: 100%;
}

.rating-container {
    font-size: 25px;
    cursor: pointer;
}

.rate-panel {
    display: flex;
    width: 680px;
    padding: 10px 0;
}

    .rate-panel > div {
        padding-right: 10px;
        line-height: 25px;
        vertical-align: middle;
    }

.info-counters {
    display: flex;
    justify-content: space-between;
}

    .info-counters .watch-count {
        text-align: left;
        margin-left: 10px;
    }

    .info-counters .rating-counter {
        text-align: right;
        margin-right: 10px;
    }

.b-content__inline_item-link {
    display: flex;
    justify-content: space-between;
}

.b-content__inline_item-context {
    position: relative;
    width: 150px;
    right: 100px;
    background: #fff;
    font-size: 14px;
    color: #777;
}

.b-content__inline_item-context-line {
    padding: 10px 0;
    border-bottom: 1px solid #777;
}

.b-content__inline_item-menu-dots {
    position: relative;
    width: 25px;
    height: 100%;
    cursor: pointer;
}

    .b-content__inline_item-menu-dots > i {
        position: absolute;
        right: 0;
    }

.wide .context-menu {
    width: 240px;
}

.context-menu {
    position: absolute;
    top: 15px;
    right: 0;
    font-size: 14px;
    width: 150px;
    background: #fff;
    border: 1px rgb(230,230,230) solid;
    box-shadow: 0px 0px 15px 11px rgba(0,0,0, 0.15);
    visibility: hidden;
}

.b-content__inline_item-menu-dots:hover .context-menu {
    visibility: visible;
}

.context-menu-item {
    border-bottom: 1px solid #777;
    padding: 5px;
    cursor: pointer;
}

    .context-menu-item:hover {
        background-color: #f0f0f0;
    }

.view3-dots {
    position: relative;
    margin-left: 95px;
    padding-right: 0 !important;
}

.view3-dots-inner {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 0 !important;
}

.b-content__inline_item.read .b-content__inline_item-cover img {
    opacity: 0.3;
}

.read-banner {
    position: absolute;
    top: 40px;
    left: 10px;
    opacity: 1 !important;
}

.base-item-heart {
    font-size: 20px;
    color: black;
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 100;
}

.base-item-heart-fill {
    color: red;
}

/*
    Reflection Module Style
*/

.reflection-module {
    background: url('../public/assets/banners/wallpaper5.png') no-repeat center;
    background-size: cover;
    height: 100%;
    margin: 0;
    /*overflow: hidden;*/
}

.reflection-module-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    /*overflow: hidden;*/
}

.reflections-list {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y;
    pointer-events: auto !important;
    overscroll-behavior: contain;
    -webkit-touch-callout: none;
}

.reflection-reply {
    background: #eaf3f9;
    border-radius: 0 5px 5px 0;
    border-left: 4px solid #3982bd;
    margin-top: 3px;
    padding-left: 10px;
}

.card-mine .reflection-reply {
    background: #caf6b1;
    border-left: 4px solid #139810;
}

.edit-group {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 10px;
}

.input-group-custom {
    display: flex;
    align-items: end;
    width: 100%;
}

    .input-group-custom textarea {
        resize: none;
        box-sizing: border-box;
    }

        .input-group-custom textarea::placeholder {
            color: #888;
            opacity: 0.8;
        }

    .input-group-custom .file-input {
        display: none;
    }

    .input-group-custom .btn-file {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

.btn-send {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    color: white;
    background: #047ff9;
    text-align: center;
    padding-top: 0.4px;
    margin-bottom: 2px;
}

.form-control:focus {
    outline: none !important;
}
/*
    Long Press Menu
*/

.longpress-menu {
    position: absolute;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
}

.longpress-item {
    padding: 10px 20px;
    cursor: pointer;
}

    .longpress-item:hover {
        background-color: #f0f0f0;
    }

.longpress-menu.longpress-menu-bordered .longpress-item:not(:first-child) {
    border-top: 1px solid #eee;
}

.longpress-item-separator {
    padding: 1px 2px;
    border-top: 1px solid #ccc;
}

.longpress-item-inactive {
    padding: 10px 20px;
}

.longpress-item-has-updates {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #047ff9; /* Background color */
    border-radius: 50%; /* Make it a circle */
    vertical-align: -3px; /* Align with the middle of the text */
}

.bottom-scroller {
    position: fixed;
    bottom: 113px;
    right: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    color: #2a78e4;
    background: #eaeee6;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .bottom-scroller.no-input {
        bottom: 70px;
    }

.reactions-teaser {
    position: fixed;
    bottom: 163px;
    right: 5px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    color: #2a78e4;
    background: #eaeee6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.focus-longpress-menu {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: rgba(127, 127, 127);
}

/*Account Profile*/

.account-profile, .account-view {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.account-profile {
    height: calc(100% - 65px);
}

.account-view {
    height: calc(100% - 65px);
}

.account-view {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: white;
}

.account-profile-container {
    height: 100%;
}

.account-profile-container-top {
    position: relative;
    height: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: rgba(150, 150, 150, 0.8);
}

    .account-profile-container-top img {
        position: relative;
        top: calc(100% - 80px);
        left: calc(100% - 80px);
    }

.account-view-container-top {
    position: relative;
    height: 60%;
}

    .account-view-container-top img {
        position: absolute;
        top: 0;
        right: 0;
    }

.account-profile-panel {
    position: absolute;
    background-color: rgba(100, 100, 100, 0.7);
    bottom: 0;
    width: 100%;
    height: 20%;
    z-index: 1;
    color: white;
    display: flex;
    flex-direction: column;
}

.account-profile-panel-name {
    padding-left: 8px;
    font-weight: bold;
    font-size: 18px;
    padding-top: 9px;
}

.account-profile-panel-points {
    padding-right: 8px;
    font-size: 18px;
    text-align: right;
    padding-top: 9px;
    font-style: italic;
}

.account-profile-panel-class {
    padding-left: 8px;
    font-size: 18px;
    padding-bottom: 9px;
}

.account-profile-panel-coins {
    padding-right: 8px;
    font-size: 18px;
    text-align: right;
    padding-bottom: 9px;
    font-style: italic;
}

.account-profile-container-bottom {
    height: 40%;
    display: flex;
    flex-direction: column;
}

.account-profile-rows {
    max-height: calc(100% - 22.8px);
    overflow-y: scroll;
}

.account-profile-photo {
    height: 100%;
    aspect-ratio: 1/1;
    margin: 10px 0;
    border-radius: 50%;
}

.account-view-photo {
    width: 100%;
    height: 100%;
}

.account-profile-container-toggle {
    display: flex;
    width: 100%;
    text-align: center;
}

    .account-profile-container-toggle div {
        flex: 1;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #7d7d7d;
        padding: 2px;
        background: #f2f2f2;
        font-size: 12px;
    }

    .account-profile-container-toggle .active {
        background: white;
        border-bottom: none;
    }

        .account-profile-container-toggle .active:not(:first-child), .account-profile-container-toggle .active:not(:last-child) {
            border-left: 1px solid #7d7d7d;
            border-right: 1px solid #7d7d7d;
        }

        .account-profile-container-toggle .active:first-child {
            border-left: none;
            border-right: 1px solid #7d7d7d;
        }

        .account-profile-container-toggle .active:last-child {
            border-right: none;
            border-left: 1px solid #7d7d7d;
        }

.account-profile-container-bottom table {
    margin-top: 10px;
}

    .account-profile-container-bottom table tr td {
        padding-left: 4px;
        padding-right: 0;
    }

        .account-profile-container-bottom table tr td:first-child {
            padding-left: 8px;
            text-align: center;
        }

.account-profile-info-message {
    padding-left: 8px;
    font-size: 12px;
    font-style: italic;
}

    .account-profile-info-message a {
        text-decoration: none;
    }

        .account-profile-info-message a span {
            text-decoration: underline;
        }

.account-profile-back-btn {
    position: absolute;
    left: 20px;
    top: 15px;
    background: rgba(100, 100, 100, 0.7);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    padding: 9px 10px 9px 8px;
    font-size: 16px;
    width: 32px;
    height: 32px;
    color: white;
    z-index: 5;
}

.account-profile-data-tbd {
    color: #047ff9 !important;
    text-decoration: underline;
}

.account-profile-clickable {
    color: #047ff9 !important;
    text-decoration: underline;
}

.challenge-view-container {
    height: calc(100% - 65px);
    padding: 0 5%;
    display: flex;
    flex-direction: column;
}

.challenge-banner-basic {
    background: url('../public/assets/banners/basic_challenge.png') no-repeat center;
    background-size: contain;
    height: 100px;
}

.challenge-banner-command {
    background: url('../public/assets/banners/command_challenge.png') no-repeat center;
    background-size: contain;
    height: 100px;
}

.challenge-banner-level {
    background: url('../public/assets/banners/level_rating.png') no-repeat center;
    background-size: contain;
    height: 100px;
}

.challenge-banner-coin {
    background: url('../public/assets/banners/coin_rating.png') no-repeat center;
    background-size: contain;
    height: 100px;
}

.challenge-banner-charity {
    background: url('../public/assets/banners/ERACharity.png') no-repeat center;
    background-size: contain;
    height: 200px;
}

.challenge-residents-count {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.challenge-view-selector {
    width: 100%;
    display: flex;
    margin: 10px 0;
    text-align: center;
}

.challenge-view-daily, .challenge-view-total {
    flex: 1;
    color: #7d7d7d;
    font-size: 14px;
}

    .challenge-view-daily svg, .challenge-view-total svg {
        fill: #7d7d7d;
    }

    .challenge-view-daily.active, .challenge-view-total.active {
        color: #047ff9;
    }

        .challenge-view-daily.active svg, .challenge-view-total.active svg {
            fill: #047ff9;
        }

    .challenge-view-daily span, .challenge-view-total span {
        text-decoration: underline;
        padding-left: 2px;
    }

.challenge-view-daily {
    border-right: 1px solid #7d7d7d;
}

.challenge-view-header {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.challenge-view-mode-description {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    position: relative;
}

.challenge-table-container {
    max-height: 426px;
    overflow-y: auto;
}

.command-table-container {
    max-height: 373px;
}

.charity-table-container {
    max-height: calc(100% - 369px);
    justify-content: center;
    align-items: center;
}

.charity-loader {
    width: 200px;
    height: 200px;
    background-color: white;
    display: none;
    justify-content: center;
    align-items: center;
}

.level-table-container {
    margin-top: 15px;
}

.challenge-view-table {
    margin: 0;
    font-size: 13px;
    margin-bottom: 0 !important;
}

.level-view-table tr td, .level-view-table tr th,
.basic-view-table tr td, .basic-view-table tr th,
.command-view-table tr td, .command-view-table tr th,
.coin-view-table tr td, .coin-view-table tr th {
    padding: 0.5rem 0 !important;
}

    .basic-view-table tr th:nth-child(1), .basic-view-table tr td:nth-child(1),
    .charity-view-table tr th:nth-child(1), .charity-view-table tr td:nth-child(1),
    .level-view-table tr th:nth-child(1), .level-view-table tr td:nth-child(1),
    .command-view-table tr th:nth-child(1), .command-view-table tr td:nth-child(1),
    .coin-view-table tr th:nth-child(1), .coin-view-table tr td:nth-child(1),
    .charity-view-table tr th:nth-child(1), .charity-view-table tr td:nth-child(1) {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }

.charity-view-table tr th:nth-child(1), .charity-view-table tr td:nth-child(1) {
    text-align: center;
}


.level-view-table tr th:nth-child(4), .level-view-table tr td:nth-child(4),
.coin-view-table tr th:nth-child(5), .coin-view-table tr td:nth-child(5) {
    padding-right: 4px !important;
}

.basic-view-table tr th:nth-child(3), .basic-view-table tr th:nth-child(4), .basic-view-table tr th:nth-child(5) {
    width: 30px;
}

.basic-view-table tr th:nth-child(1), .basic-view-table tr th:nth-child(3), .basic-view-table tr th:nth-child(4), .basic-view-table tr th:nth-child(5),
.basic-view-table tr td:nth-child(1), .basic-view-table tr td:nth-child(3), .basic-view-table tr td:nth-child(4), .basic-view-table tr td:nth-child(5) {
    text-align: center;
}

.charity-view-table.view-mode-0 tr th:nth-child(4),
.charity-view-table.view-mode-1 tr th:nth-child(5) {
    text-align: center;
}

.charity-view-table tr th:nth-child(2), .charity-view-table tr th:nth-child(3),
.charity-view-table.view-mode-0 tr th:nth-child(4), .charity-view-table.view-mode-1 tr th:nth-child(5),
.charity-view-table tr td:nth-child(2), .charity-view-table tr td:nth-child(3) {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.charity-view-table.view-mode-1 tr td:nth-child(4) {
    text-align: center;
}

.charity-view-table.view-mode-0 tr td:nth-child(4),
.charity-view-table.view-mode-1 tr td:nth-child(5) {
    padding-left: 0 !important;
    padding-right: 4px !important;
    text-align: right;
}

.level-view-table tr th:nth-child(1), .level-view-table tr th:nth-child(4),
.level-view-table tr td:nth-child(1), .level-view-table tr td:nth-child(4) {
    text-align: center;
}

.command-view-table tr th:nth-child(1), .command-view-table tr th:nth-child(4),
.command-view-table tr td:nth-child(1), .command-view-table tr td:nth-child(4) {
    text-align: center;
}

.command-view-table tr th:nth-child(2) {
    padding-left: 5px !important;
}

.coin-view-table tr th:nth-child(1), .coin-view-table tr th:nth-child(4),
.coin-view-table tr td:nth-child(1), .coin-view-table tr td:nth-child(4) {
    text-align: center;
}

.challenge-charity-total {
    font-size: 13px;
    margin-top: 10px;
    line-height: 13px;
    text-align: right;
}

.challenge-switcher {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

    .challenge-switcher.inactive {
        color: #cccccc;
    }

    .challenge-switcher.challenge-switcher-left {
        left: 20%;
    }

    .challenge-switcher.challenge-switcher-begin {
        left: 10%;
    }

    .challenge-switcher.challenge-switcher-right {
        right: 20%;
    }

    .challenge-switcher.challenge-switcher-end {
        right: 10%;
    }

.charity-date {
    text-align: center;
    display: inline-block;
    font-size: 16px;
    line-height: 18px;
}

.travel-logo {
    margin-left: 4px;
    margin-top: 5px;
}

.challenge-view-table > thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
}

.challenge-view-table tr th.total-sort-column {
    color: #047ff9;
}

    .challenge-view-table tr th.total-sort-column svg {
        fill: #047ff9;
    }

.challenge-table-expander {
    color: #047ff9 !important;
    text-decoration: underline;
}

.challenge-conditions-link {
    font-size: 12px;
    font-style: italic;
    margin-top: 21px;
}

    .challenge-conditions-link a {
        text-decoration: none;
    }

        .challenge-conditions-link a span {
            text-decoration: underline;
        }

.full-post p {
    margin-bottom: 0px;
}

/* Layout */

.main-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    overflow-y: hidden !important;
}

.top-panel-layout {
    text-align: center;
    position: relative;
    width: 100%;
    color: #7d7d7d;
}

    .top-panel-layout .top-panel-layout-center-text {
        display: inline-block;
        position: relative;
    }

    .top-panel-layout .top-panel-layout-offset {
        position: relative;
        display: inline-block;
        transform: translateX(20px);
    }


.input-panel {
    display: flex;
    align-items: end;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 5px;
    flex-direction: column;
    position: relative;
    bottom: 0;
}

    .input-panel textarea {
        resize: none;
        box-sizing: border-box;
        border-radius: 20px !important;
    }

        .input-panel textarea::placeholder {
            color: #7d7d7d;
            opacity: 0.8;
        }

.input-panel-send-block {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-left: 2%;
}

.bottom-panel {
    padding-bottom: 15px !important;
}

.bottom-panel-layout {
    display: flex;
    width: 100%;
    color: #7d7d7d;
}

.millionaire-morning-module-layout, .stream-module-layout, .reflection-module-layout {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y;
    pointer-events: auto !important;
    overscroll-behavior: contain;
    -webkit-touch-callout: none;
}

.millionaire-morning-module-layout, .stream-module-layout, .reflection-module-layout {
    height: 100%;
    margin: 0;
}

    .reflection-module-layout .container-fluid, .stream-module-layout .container-fluid {
        padding-left: 3px !important;
        padding-right: 0px !important;
    }

.bottom-panel-layout-millionaire-morning, .bottom-panel-layout-streams, .bottom-panel-layout-reflections,
.bottom-panel-layout-founder-channel, .bottom-panel-layout-challenge, .bottom-panel-layout-account-profile, .bottom-panel-layout-admin {
    flex: 1;
    text-align: center;
    font-size: 14px;
    position: relative;
}

    .bottom-panel-layout-millionaire-morning.active, .bottom-panel-layout-streams.active, .bottom-panel-layout-reflections.active,
    .bottom-panel-layout-challenge.active, .bottom-panel-layout-account-profile.active, .bottom-panel-layout-admin.active {
        color: #047ff9;
    }

.bottom-panel-layout-founder-channel {
    top: -1.25px;
}

    .bottom-panel-layout-founder-channel.active svg {
        fill: #047ff9;
        stroke: #047ff9;
    }

    .bottom-panel-layout-founder-channel svg {
        fill: #7d7d7d;
        stroke: #7d7d7d;
    }

.bottom-panel-avatar {
    width: 100%;
}

.main-loader-cover {
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center; /* Horizontal alignment */
    align-items: center; /* Vertical alignment */
    flex-direction: column;
    color: white;
}

.main-loader-cover-username {
    position: absolute;
    top: 16%;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-in forwards;
}

.main-loader-cover-message {
    position: absolute;
    bottom: 110px;
    left: 0;
    width: 100%;
    padding: 0 60px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    opacity: 0; /* Initially hidden */
    animation: fadeIn 1s ease-in forwards;
    animation-delay: 1s;
    white-space: pre-line;
    line-height: 16px;
}

.main-loader-cover-expiration {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 80px;
    height: 80px;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
    opacity: 0; /* Initially hidden */
    animation: fadeIn 0s ease-in forwards;
    animation-delay: 2s;
    color: white;
    line-height: 11px;
    padding-top: 10px;
}

.main-loader-cover.morning .main-loader-cover-username,
.main-loader-cover.day .main-loader-cover-username {
    color: black;
}

.date-header {
    text-align: center;
    margin: 8px 0 9px 0;
    line-height: 12px;
}

    .date-header span {
        border: none;
        border-radius: 20px;
        padding: 1px 5px;
        color: white;
        font-size: 12px;
    }

        .date-header span.date-header-span-reflections {
            background: hsl(103.40425531914893, 39.17142857142857%, 42.69607843137255%, 0.4);
        }

        .date-header span.date-header-span-streams {
            background: rgba(118, 178, 183, 0.4);
        }

        .date-header span.date-header-span-millionaire-morning {
            background: rgba(80, 80, 80, 0.4);
        }

.unread-sticker {
    padding: 2px 5px;
    border-radius: 10px; /* This makes the shape a circle */
    background-color: #fd3b2f; /* Change color as needed */
    text-align: center;
    color: white; /* Color of the number */
    font-size: 8px; /* Adjust font size as needed */
    position: absolute;
    left: 50%;
    top: -5px;
    min-width: 15px;
    min-height: 15px;
    z-index: 1;
}

.bottom-scroller-counter {
    position: absolute;
    background: #047ff9;
    top: -7px;
    font-weight: bold;
    font-size: 8px;
    color: white;
    border-radius: 10px;
    padding: 0 5px;
}

.reflection-card, .millionaire-morning-card {
    display: flex;
    width: 100%;
    position: relative;
}

.unread-layer {
    position: absolute;
    top: 0;
    left: -10px;
    width: calc(100% + 20px);
    height: 100%;
    z-index: 1;
    background: rgba(207, 223, 255, 0.2);
}

.content-editable {
    min-height: 30px;
    max-height: 60px;
    overflow: auto;
    padding: 2px 0 2px 10px;
    width: 100%;
    border: 1px solid #7d7d7d;
    background: white;
    border-radius: 20px;
}

.ql-container {
    border-radius: 20px;
    height: auto !important;
    padding-bottom: 0 !important;
}

.ql-editor {
    min-height: 30px;
    max-height: 240px;
    font-size: 16px;
    padding: 2px 0 2px 10px !important;
    line-height: 24px;
}

    .ql-editor.ql-blank::before {
        font-style: normal !important;
        left: 10px !important;
        top: 4px !important;
    }

.content-editable:focus {
    outline: none;
    max-height: 240px;
}

.ql-editor:focus {
    outline: none;
    max-height: 240px;
}

[contenteditable=true]:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
    color: #7d7d7d;
    opacity: 0.8;
}

.formatting-menu {
    position: absolute;
    background-color: #262a22;
    color: #fafdf9;
    border: none;
    z-index: 1000;
    padding: 0 5px;
    border-radius: 10px;
    display: flex;
}

    .formatting-menu div {
        padding: 5px 10px;
        border-right: 1px solid #464d44;
    }

        .formatting-menu div:last-child {
            border-right: none;
        }

.reflection-evaluation {
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
}

.reflection-evaluation-header {
    padding: 2px 0;
}

.reflection-evaluation-button {
    border: 1px solid #7d7d7d;
    border-radius: 20px;
    margin-left: 10px;
    padding: 2px 5px;
}

    .reflection-evaluation-button.active {
        background: #3a833a;
        color: white;
    }

.reflection-value {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 9px;
    padding: 2px 4px;
    border-radius: 10px;
    background: #7d7d7d;
    color: white;
}

    .reflection-value.reflection-value-unevaluated {
        background: orange;
        color: initial;
    }

    .reflection-value.reflection-value-evaluated {
        background: #3a833a;
        color: white;
    }

.reflection-value-resident {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 9px;
    padding: 0 2px;
    font-size: 13px;
    font-weight: bold;
    color: #7d7d7d;
}

    .reflection-value-resident.exclusive {
        color: #ead53d !important;
    }

    .reflection-value-resident.basic {
        color: #007bff !important;
    }

    .reflection-value-resident.standard {
        color: #c751fa !important;
    }

    .reflection-value-resident.stdplus {
        color: #8d9d2b !important;
    }

    .reflection-value-resident.bus {
        color: saddlebrown !important;
    }

    .reflection-value-resident.busplus {
        color: black !important;
    }

.reflection-send-status .fa-check::before,
.reflection-send-status .fa-clock::before {
    vertical-align: bottom;
}

.reflection-first span {
    background: hsl(103.40425531914893, 39.17142857142857%, 42.69607843137255%, 0.7);
    color: white;
    border-radius: 10px;
    padding: 2px 7px;
    font-size: 13px;
}

.reflection-badges {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.ambassador-badge span {
    color: white;
    border-radius: 10px;
    padding: 2px 7px;
    font-size: 13px;
    background: rgba(108, 27, 28, 0.7);
}

.achievement-badge span {
    color: white;
    border-radius: 10px;
    padding: 2px 7px;
    font-size: 13px;
    background: rgba(108, 27, 28, 0.7);
}

.achievement-badge.exclusive span {
    background-color: #ead53d;
}

.achievement-badge.basic span {
    background-color: #007bff;
}

.achievement-badge.standard span {
    background-color: #c751fa;
}

.achievement-badge.stdplus span {
    background-color: #8d9d2b;
}

.achievement-badge.bus span {
    background-color: saddlebrown;
}

.achievement-badge.busplus span {
    background-color: black;
}

.achievement-badge span svg {
    fill: white;
    stroke: white;
}

:root {
    --safe-area-inset-bottom: calc(100vh - var(--tg-viewport-stable-height, 100vh))
}

.classic-view {
    z-index: 5;
    background: white;
    position: absolute;
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
}

.classic-view-close-btn {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(100, 100, 100, 0.7);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 7px 10px 8px 9px;
    font-size: 13px;
    width: 24px;
    height: 24px;
    color: white;
    z-index: 2;
}

.classic-view-header {
    height: 64px;
    text-align: center;
    padding: 10px 0;
}

.classic-view-body {
    flex: 1;
    flex-direction: row;
    height: calc(100% - 64px);
}

.classic-view-body-groups {
    width: 25%;
    border-right: 1px solid #eee;
}

.classic-view-body-group {
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
    background: #ddd;
}

    .classic-view-body-group.selected {
        background: white;
        border-right: none;
    }

.classic-view-body-accounts {
    flex: 1;
    display: flex;
    flex-direction: column;
}

    .classic-view-body-accounts table thead {
        position: sticky;
        top: 0;
        z-index: 2;
        background: white;
    }

.classic-view-body-accounts-header {
    height: 44px;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
}

.classic-view-body-accounts-list {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px;
}

.classic-view-selector {
    width: 100%;
    display: flex;
    margin: 5px 0;
    text-align: center;
    height: 24px;
}

.classic-view-selector-option {
    flex: 1;
    color: #7d7d7d;
    font-size: 14px;
    border-right: 1px solid #7d7d7d;
}

    .classic-view-selector-option:last-child {
        border-right: none;
    }

    .classic-view-selector-option svg {
        fill: #7d7d7d;
    }

    .classic-view-selector-option.active {
        color: #047ff9;
    }

        .classic-view-selector-option.active svg {
            fill: #047ff9;
        }

    .classic-view-selector-option span {
        text-decoration: underline;
        padding-left: 2px;
    }

.classic-view-line-count {
    height: 24px;
    width: 100%;
    padding: 10px 0 10px 10px;
    font-size: 12px;
    font-style: italic;
}

.classic-view-table-container {
    max-height: calc(100% - 210px);
    padding: 0 10px;
    overflow-y: auto;
}

    .classic-view-table-container table thead tr th:nth-child(2) {
        padding-left: 6px !important;
    }

    .classic-view-table-container table thead tr th:nth-child(3),
    .classic-view-table-container table tbody tr td:nth-child(3) {
        padding-left: 0 !important;
    }

.wallet-transactions-view {
    z-index: 2;
    background: white;
    position: absolute;
    width: 100%;
    height: calc(100% - 65px);
    display: flex;
    flex-direction: column;
}

.wallet-transactions-view-close-btn {
    position: absolute;
    left: 10px;
    top: 10px;
    background: rgba(100, 100, 100, 0.7);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    padding: 7px 10px 8px 9px;
    font-size: 13px;
    width: 24px;
    height: 24px;
    color: white;
    z-index: 2;
}

.wallet-transactions-view-header {
    height: 44px;
    text-align: center;
    padding: 10px 0;
}

.wallet-transactions-current-balance {
    height: 88px;
    text-align: center;
    padding: 20px 0;
    line-height: 48px;
    font-size: 24px;
}

.wallet-transactions-balance-contents {
    display: flex;
    flex-direction: row;
    text-align: center;
}

.wallet-transactions-balance-cp {
    flex: 1;
}

.wallet-transactions-balance-ecp {
    flex: 1;
}

.wallet-transactions-balance-ncp {
    flex: 1;
}

.wallet-transactions-current-balance.active {
    color: #2a78e4;
}

.wallet-transactions-balance-cp.active {
    color: green;
}

.wallet-transactions-balance-ecp.active {
    color: goldenrod;
}

.wallet-transactions-balance-ncp.active {
    color: red;
}

.wallet-transactions-view-table-container {
    max-height: calc(100% - 132px);
    padding: 0 10px;
    overflow-y: auto;
}

    .wallet-transactions-view-table-container table tr:nth-child(2n+1) td:not(:first-child) {
        border-bottom: none !important;
    }

    .wallet-transactions-view-table-container table tr td:first-child {
        text-align: center;
        vertical-align: middle;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .wallet-transactions-view-table-container table tr td .spend,
    .wallet-transactions-view-table-container table tr td .income,
    .wallet-transactions-view-table-container table tr td .missed {
        width: 32px;
        height: 32px;
    }

        .wallet-transactions-view-table-container table tr td .spend svg {
            fill: orange;
            stroke: orange;
        }

        .wallet-transactions-view-table-container table tr td .income svg {
            fill: rgb(0, 150, 0);
            stroke: rgb(0, 150, 0);
        }

        .wallet-transactions-view-table-container table tr td .missed svg {
            fill: red;
            stroke: red;
        }

    .wallet-transactions-view-table-container table tr:nth-child(2n+1) td {
        padding-bottom: 2px !important;
    }

    .wallet-transactions-view-table-container table tr:nth-child(2n) td {
        padding-top: 2px !important;
    }

.wallet-transaction-type {
    padding-left: 0 !important;
}

.wallet-transaction-value {
    text-align: right;
}

.wallet-transaction-second-row {
    display: flex;
    color: #7d7d7d !important;
    font-size: 14px !important;
}

.wallet-transaction-second-row-datetime {
    text-align: left;
}

.wallet-transaction-second-row-initiator {
    flex: 1;
}

.wallet-transaction-value-positive {
    color: rgb(0, 150, 0);
    font-weight: bold;
}

.wallet-transaction-value-missed {
    color: red;
    font-weight: bold;
}


.reg-module-container {
    height: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
}

.reg-module-view-header {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 10px;
}

.reg-module-space {
    flex: 1;
}

.reg-module-block {
    margin-top: 10px;
}

.reg-module-label {
    color: #7d7d7d;
    font-size: 13px;
    margin-bottom: 2px;
    padding-left: 2px;
}

.reg-module-input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #ccc;
    text-indent: 5px;
}

.reg-module-optional {
    font-size: 13px;
    margin-top: 8px;
}

.reg-module-submit {
    margin-top: 15px;
}

    .reg-module-submit, .reg-module-submit button {
        width: 100%;
    }

.reg-module-thx {
    text-align: center;
}

.reg-module-wait {
    flex: 1;
    height: 100%;
    text-align: center;
    padding-top: 150px;
}

.reg-module-autocomplete {
    position: relative;
}

.reg-module-autocomplete-input {
    position: relative;
}

    .reg-module-autocomplete-input i {
        position: absolute;
        right: 10px;
        top: 6px;
        color: #7d7d7d;
    }

.reg-module-autocomplete-options {
    max-height: 200px;
    z-index: 2;
    background: white;
    overflow-y: auto;
    position: absolute;
    top: 30px;
    width: 100%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.reg-module-autocomplete-option {
    width: 100%;
    padding: 5px 10px;
}

.reg-module-datepicker, .reg-module-datetimepicker {
    display: flex;
}

    .reg-module-datepicker select, .reg-module-datetimepicker select {
        border: 1px solid #ccc;
        border-radius: 10px;
        text-indent: 5px;
    }

        .reg-module-datetimepicker select:not(:first-of-type) {
            margin-left: 4px;
        }

.reg-module-datepicker-day {
    width: 20%;
}

.reg-module-datepicker-month {
    width: 55%;
    margin: 0 10px;
}

.reg-module-datepicker-year {
    width: 25%;
}

.reg-module-datetimepicker-day,
.reg-module-datetimepicker-month,
.reg-module-datetimepicker-hours,
.reg-module-datetimepicker-minutes,
.reg-module-datetimepicker-seconds {
    width: 17.5%;
}

.reg-module-datetimepicker-year {
    width: 20%;
}

.reg-module-privacy {
    text-align: center;
    font-size: 13px;
    margin-bottom: 10px;
}

    .reg-module-privacy a, .reg-module-privacy a:visited {
        text-decoration: none;
        color: #047ff9;
    }

.reg-module-view-payment-description {
    font-size: 13px;
}

    .reg-module-view-payment-description p {
        margin: 0;
    }

.countdown {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 10px solid orange;
    text-align: center;
    align-items: center;
    font-size: 32px;
    line-height: 80px;
    margin: 10px auto;
}

.btn-approval {
    font-size: 15px;
    padding: 0 !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
}

    .btn-approval:not(:first-child) {
        margin-left: 5px;
    }

    .btn-approval:disabled {
        padding: 0 !important;
        width: 30px !important;
        height: 30px !important;
        color: rgba(16, 16, 16, 0.3) !important;
        border-color: rgba(118, 118, 118, 0.3) !important;
        background: rgba(239, 239, 239, 0.3) !important;
    }

.btn-approval-confirm:not(:disabled) {
    color: green;
    border-color: green;
}

.btn-approval-decline:not(:disabled) {
    color: orange;
    border-color: orange;
}

.btn-approval-revert:not(:disabled) {
    color: red;
    border-color: red;
}

.payment-approval-container {
    height: calc(100% - 65px);
}

.payment-approval-container-vh {
    height: calc(100vh - 65px);
}

.btn-payment-add-manual {
    font-size: 13px !important;
    padding: 5px !important;
    margin-left: 5%;
}

.btn-payment-add-back {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 24px;
    height: 24px;
    color: black;
}

.btn-payment-edit {
    font-size: 13px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    display: inline-block;
    margin-left: 5px;
}

.payment-approval-table-container {
    max-height: calc(100% - 74px);
    overflow-y: auto;
    margin-top: 10px;
}

.payment-approval-manual-container {
    height: calc(100% - 44px);
    padding: 0 10px;
    overflow-y: auto;
}

.payment-approval-success-container {
    height: calc(100% - 44px);
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-approval-success-message {
    display: flex;
    width: 70%;
    flex-direction: column;
    line-height: 18px;
}

.payment-approval-status {
    font-size: 18px;
}

.payment-approval-status-0 {
    color: gray;
}

.payment-approval-status-7 {
    color: green;
}

.payment-approval-status-100 {
    color: orange;
}

.payment-approval-status-200 {
    color: red;
}

.payment-reminder {
    color: white;
    z-index: 10;
    height: 68px;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    width: 100%;
    position: absolute;
    top: 0;
    padding: 10px 20px;
}

.payment-reminder-close-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 20px;
    height: 20px;
}

.admin-panel-tab {
    display: flex;
    width: 100%;
    text-align: center;
    height: 20px;
}

    .admin-panel-tab div {
        flex: 1;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #7d7d7d;
        padding: 2px;
        background: #f2f2f2;
        font-size: 11px;
    }

    .admin-panel-tab .admin-panel-tab-item-selected {
        background: white;
        border-bottom: none;
        border-top: 1px solid #7d7d7d;
    }

        .admin-panel-tab .admin-panel-tab-item-selected:not(:first-child), .admin-panel-tab .admin-panel-tab-item-selected:not(:last-child) {
            border-left: 1px solid #7d7d7d;
            border-right: 1px solid #7d7d7d;
        }

        .admin-panel-tab .admin-panel-tab-item-selected:first-child {
            border-left: none;
            border-right: 1px solid #7d7d7d;
        }

        .admin-panel-tab .admin-panel-tab-item-selected:last-child {
            border-right: none;
            border-left: 1px solid #7d7d7d;
        }

.reaction-menu-cover {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: rgba(127, 127, 127, 0.5);
    backdrop-filter: blur(10px);
}

.reaction-menu-content {
    position: absolute;
    top: 200px;
    left: 20px;
    text-align: center;
}

.reaction-big {
    font-size: 72px;
}

.reaction-counter {
    border-radius: 20px;
    margin: 0 5px;
    padding: 0 5px;
    cursor: pointer;
    background: white;
    display: inline-block;
}

.reaction-counter-bg {
    border-radius: 20px;
    margin: 0 5px;
    padding: 0 10px;
    cursor: pointer;
    background: #ccc;
    display: inline-block;
}

.reaction-counter-selected {
    border-radius: 20px;
    margin: 0 5px;
    padding: 0 5px;
    cursor: pointer;
    background: lightblue;
    display: inline-block;
}

.reaction-menu {
    max-height: 250px;
    text-align: initial;
    overflow-y: auto;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 10px;
}

.reaction-datetime {
    color: #7d7d7d;
    font-size: 13px;
}

.admin-main-screen-options {
    width: 100%;
    height: calc(100% - 44px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-main-screen-option {
    height: 100px;
    border-radius: 10px;
    background: #ccc;
    margin: 2.5% 10%;
    width: 250px;
    display: flex;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.wallet-transactions-filter-panel {
    padding: 0 10px;
    margin: 5px 0;
    width: 100%;
    font-size: 14px;
}

.wallet-transactions-filter-switcher {
    color: #7d7d7d;
    font-size: 16px;
    padding: 0 10px;
    margin-top: 5px;
}

.wallet-transactions-filter-block {
    display: flex;
    margin: 5px 0;
}

.wallet-transactions-filter-select {
    margin-left: 5px;
    flex: 1;
}

.wallet-transactions-filter-total {
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-size: 20px;
}

.charity-medal {
    width: 20px;
    height: 20px;
    border-radius: 50%; /* Makes it a perfect circle */
    background-color: gold; /* Circle background */
    display: flex; /* Flexbox for centering content */
    justify-content: center; /* Horizontally center the number */
    align-items: center; /* Vertically center the number */
    font-weight: bold; /* Make the number bold */
    margin: auto; /* Centers the circle inside the td */
}

.binar-team-side-switcher {
    display: flex;
    margin: 10px;
    border-radius: 5px;
    border: 1px solid #7d7d7d;
    background: lightgray;
}

.binar-team-side-switcher-variant-selected {
    flex: 1;
    color: green;
    border-radius: 5px;
    border: 1px solid #7d7d7d;
    text-align: center;
    background: white;
    padding: 10px;
}

.binar-team-side-switcher-variant {
    flex: 1;
    color: darkgray;
    text-align: center;
    padding: 10px;
}

.binar-lost-pts-switcher,
.wallet-lost-pts-switcher {
    display: flex;
    margin: 10px;
    line-height: 11px;
}

.binar-lost-pts-switcher-caption,
.wallet-lost-pts-switcher-caption {
    flex: 1;
    text-align: left;
    font-style: italic;
    padding-left: 5px;
    font-size: 14px;
}

.binar-lost-pts-switcher-element,
.wallet-lost-pts-switcher-element {
    display: flex;
    align-items: center;
}

.binar-team-scrollable-container-my {
    overflow-y: auto;
    max-height: calc(100% - 284.8px);
}

.binar-team-scrollable-container {
    overflow-y: auto;
    max-height: calc(100% - 269.8px);
}

.binar-pts {
    margin: 10px;
    background: #eee;
    padding: 10px;
    display: flex;
}

.binar-pts-left-block {
    flex: 1;
    text-align: center;
}

.binar-pts-middle-block {
    flex: 1;
    text-align: center;
}

.binar-pts-right-block {
    flex: 1;
    text-align: center;
}

.binar-view-second-line {
    display: flex;
    margin: 0;
}

.binar-view-third-line {
    display: flex;
    margin: 0;
}

.binar-last-account {
    margin-bottom: 10px;
}

.binar-last-account-label {
    background: #eee;
    color: darkgray;
    font-weight: bold;
    padding: 10px;
    margin: 10px;
}

.binar-big-block {
    margin: 0 10px;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    border: 1px solid #7d7d7d;
    position: relative;
}

.binar-big-avatar {
    display: flex;
    flex-direction: column;
}

.binar-big-class {
    color: gray;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    margin-top: 3px;
}

.binar-big-block-right-section {
    margin-left: 10px;
}

.binar-big-block-name {
    font-weight: bold;
}

.binar-big-block-id {
}

.binar-big-block-activity-info {
    display: flex;
    color: white;
    font-size: 12px;
}

.binar-big-block-activity-info-item-green {
    background-color: green;
    border-radius: 5px;
    padding: 0 5px;
    margin-right: 5px;
}

.binar-big-block-activity-info-item-red {
    background-color: red;
    border-radius: 5px;
    padding: 0 5px;
    margin-right: 5px;
}

.binar-medium-block {
    width: 150px;
    height: 150px;
    flex: 1;
    margin: 0 10px;
    border: 1px solid #7d7d7d;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.binar-medium-class {
    font-size: 12px;
}

.binar-small-avatar {
    line-height: 8px;
}

.binar-small-class,
.binar-small-block-id {
    font-size: 10px;
}

.binar-medium-block-plus,
.binar-medium-block-lock {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
}


.binar-small-block {
    width: 60px;
    height: 60px;
    flex: 1;
    margin: 0 10px;
    border: 1px solid #7d7d7d;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.binar-small-block-plus,
.binar-small-block-lock {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.binar-medium-block-plus,
.binar-small-block-plus {
    color: green;
}

.binar-medium-block-lock,
.binar-small-block-lock {
    color: lightgray;
}

.binar-medium-block-activity-info,
.binar-small-block-activity-info {
    position: relative;
    height: 10px;
}

.binar-medium-block-activity-info-item-green,
.binar-small-block-activity-info-item-green {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: green;
    position: absolute;
    border: 1px solid white;
}

.binar-medium-block-activity-info-item-red,
.binar-small-block-activity-info-item-red {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    border: 1px solid white;
}

    .binar-medium-block-activity-info-item-green:first-child,
    .binar-small-block-activity-info-item-green:first-child,
    .binar-medium-block-activity-info-item-red:first-child,
    .binar-small-block-activity-info-item-red:first-child {
        left: calc(50% - 8px);
    }

    .binar-medium-block-activity-info-item-green:last-child,
    .binar-small-block-activity-info-item-green:last-child,
    .binar-medium-block-activity-info-item-red:last-child,
    .binar-small-block-activity-info-item-red:last-child {
        left: calc(50% - 2px);
    }

.binar-nav-panel {
    display: flex;
    position: sticky;
    bottom: 0;
    margin: 10px;
    gap: 10px;
}

.binar-nav-panel-btn {
    text-align: center;
    border-radius: 5px;
    flex: 1;
    background: #eee;
    padding: 10px;
}

.tooltip-component {
    background: #eee;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    z-index: 100;
}

.binar-warning-modal-layer {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(127, 127, 127, 0.5);
    backdrop-filter: blur(10px);
}

.binar-warning-modal {
    margin: 20px;
    background: white;
    position: relative;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 0 20px;
}

.binar-warning-modal-btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 16px;
}

.binar-warning-modal-header {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 0;
}

.binar-warning-text {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.binar-warning-check {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.binar-warning-next {
    position: absolute;
    width: calc(100% - 40px);
    text-align: center;
    font-size: 16px;
    bottom: 20px;
}

.binar-changes-sticker {
    display: inline-block;
    margin-left: 1px;
    border-radius: 10px; /* This makes the shape a circle */
    background-color: #fd3b2f; /* Change color as needed */
    min-width: 15px;
    min-height: 15px;
}

.cashback-transactions-balance-contents {
    display: flex;
    flex-direction: row;
    text-align: center;
    padding-bottom: 20px;
}

.cashback-transactions-view-table-container {
    max-height: calc(100% - 200px);
    padding: 0 10px;
    overflow-y: auto;
}

    .cashback-transactions-view-table-container table tr:nth-child(2n+1) td:nth-child(2) {
        font-size: 14px;
    }

    .cashback-transactions-view-table-container table tr:nth-child(2n+1) td:nth-child(3) {
        width: 30%;
        border-bottom: none !important;
        padding-right: 0 !important;
    }

    .cashback-transactions-view-table-container table tr td:first-child {
        text-align: center;
        vertical-align: middle;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .cashback-transactions-view-table-container table tr td .spend,
    .cashback-transactions-view-table-container table tr td .income,
    .cashback-transactions-view-table-container table tr td .missed {
        width: 32px;
        height: 32px;
    }

        .cashback-transactions-view-table-container table tr td .spend svg {
            fill: orange;
            stroke: orange;
        }

        .cashback-transactions-view-table-container table tr td .income svg {
            fill: rgb(0, 150, 0);
            stroke: rgb(0, 150, 0);
        }

        .cashback-transactions-view-table-container table tr td .missed svg {
            fill: red;
            stroke: red;
        }

    .cashback-transactions-view-table-container table tr:nth-child(2n) td {
        padding-top: 2px !important;
    }

.cashback-transaction-type {
    padding-left: 0 !important;
}

.cashback-transaction-value {
    text-align: right;
}

.cashback-transaction-second-row-datetime {
    text-align: right;
    color: #7d7d7d !important;
    font-size: 14px !important;
}

.cashback-transaction-value-positive {
    color: rgb(0, 150, 0);
    font-weight: bold;
}

.cashback-transaction-value-missed {
    color: red;
    font-weight: bold;
}

.cashback-transactions-filter-block {
    display: flex;
    margin: 10px 0;
}

.btn-header-right {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 24px;
    height: 24px;
    color: black;
}

.search-panel {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: rgba(127, 127, 127, 0.5);
}

.search-panel-input {
    position: relative;
    top: 40px;
    padding: 0 10px;
}

select option[hidden] {
    display: none;
}

select option {
    font-style: normal;
}

.filter-module-autocomplete {
    flex: 1;
    margin-left: 5px;
    position: relative;
}

.filter-module-autocomplete-input {
    position: relative;
}

.filter-module-input {
    width: 100%;
    text-indent: 3px;
    font-size: 14px;
    padding: 0;
}

.filter-module-autocomplete-options {
    max-height: 200px;
    z-index: 2;
    background: white;
    overflow-y: auto;
    position: absolute;
    top: 30px;
    width: 100%;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.filter-module-autocomplete-input i.fa-chevron-down {
    position: absolute;
    font-size: 10px;
    right: 4px;
    top: 7px;
    color: black;
}

.filter-module-autocomplete-input i.fa-xmark {
    position: absolute;
    font-size: 10px;
    right: 20px;
    top: 7px;
    color: black;
}

.filter-module-autocomplete-option {
    width: 100%;
    padding: 5px 10px;
}

.incoming-requests-sticker {
    color: white;
    background-color: orange;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    margin-left: 4px;
    font-size: 12px;
    font-weight: bold;
}

.binar-packs {
    color: black !important;
    text-decoration: underline;
}


.dashboard-cover {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: rgba(127, 127, 127, 0.5);
}

.dashboard-body {
    background: white;
    position: relative;
    margin: 40px 10px;
    border-radius: 10px;
    font-size: 12px;
    height: calc(100% - 80px);
}

.dashboard-header {
    text-align: center;
}

.dashboard-close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
}

.dashboard-chapter:not(:last-child) {
    border-bottom: 1px solid black;
}

.dashboard-chapter-header {
    text-align: center;
    font-weight: bold;
    margin: 10px 0;
}

.dashboard-chapter-content {
    padding: 0 10px;
}

.dashboard-bottom-panel {
    text-align: center;
    padding-bottom: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
}


.motivation-fact-cover {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: rgba(127, 127, 127, 0.5);
}

.motivation-fact-body {
    background: white;
    position: relative;
    margin: 300px 10px;
    border-radius: 10px;
    font-size: 16px;
    height: calc(100% - 600px);
    display: flex;
    flex-direction: column;
}

.motivation-fact-header {
    text-align: center;
    font-size: 20px;
    margin: 10px 0;
}

.motivation-fact-close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 18px;
}

.motivation-fact-content {
    padding: 0 10px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.motivation-fact-bottom-panel {
    text-align: center;
    width: 100%;
    margin: 10px 0;
}

.payment-lock-screen-cover {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: rgba(127, 127, 127, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-lock-screen-content {
    background: white;
    padding: 20px 10px;
    border-radius: 10px;
    margin: 0 20px;
    text-align: center;
}

.binar-big-block-info {
    position: absolute;
    top: 3px;
    right: 7px;
}

.binar-medium-block-info {
    position: absolute;
    top: 3px;
    right: 7px;
}

.binar-account-info-cover {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: rgba(127, 127, 127, 0.5);
}

.binar-account-info-body {
    background: white;
    position: relative;
    margin: 50px 10px;
    border-radius: 10px;
    font-size: 16px;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
}

.binar-account-info-header {
    text-align: center;
    font-size: 20px;
    margin: 10px 0;
    height: 30px;
}

.binar-account-info-close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 18px;
}

.binar-account-info-content {
    padding: 0 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    overflow-y: auto;
}

.binar-account-info-chapter:not(:first-child) .binar-account-info-chapter-header {
    margin-top: 10px;
}

.binar-account-info-chapter-header {
    text-align: center;
    font-weight: bold;
}

.binar-account-info-chapter-content {
    padding: 0 10px;
}

    .binar-account-info-chapter-content p {
        margin-bottom: 0;
    }

.binar-account-info-bottom-panel {
    text-align: center;
    width: 100%;
    margin: 10px 0;
    height: 37.6px;
}

.switcher {
    width: 35px; /* Overall switch width */
    height: 15px; /* Overall switch height */
    background-color: #ccc; /* Default background */
    border-radius: 15px; /* Rounded switch */
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease; /* Smooth background change */
}

    .switcher.on {
        background-color: #4caf50; /* Background for ON state */
    }

    .switcher.off {
        background-color: #f44336; /* Background for OFF state */
    }

/* Slider Button */
.switcher-slider {
    width: 10px; /* Button width */
    height: 10px; /* Button height */
    background-color: white; /* Button color */
    border-radius: 50%; /* Circular button */
    position: absolute;
    transition: transform 0.3s ease; /* Smooth slide animation */
}

/* Slider Position for ON State */
.switcher.on .switcher-slider {
    transform: translateX(15px); /* Move to the right */
}

/* Slider Position for OFF State */
.switcher.off .switcher-slider {
    transform: translateX(0); /* Move to the left */
}

.progress-bar-container {
    width: 100px;
    height: 25px;
    background-color: #bbb; /* Light gray background */
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    margin: 5px 0;
}

.progress-bar-fill {
    height: 100%;
    background: linear-gradient(to right, #0141bb 0%, #08d3f2 100%);
    transition: width 0.3s ease; /* Smooth transition */
}

    .progress-bar-fill.warning {
        background: linear-gradient(to right, #7b9d02 0%, #c7ec2a 100%);
    }

    .progress-bar-fill.danger {
        background: linear-gradient(to right, #9a0105 0%, #eb2635 100%);
    }

    .progress-bar-fill.special {
        background: linear-gradient(to right, #6103a0 0%, #a83dff 100%);
    }

.progress-bar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: white;
    font-weight: bold;
    width: 100%;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Text shadow for readability */
}

.reg-module-caption {
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.validation-error-field > .form-control:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid red;
}

.validation-error-text {
    color: red;
}

.qr-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.qr-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 90%;
    width: 300px;
    display: flex; /* Flexbox ��� ������������ ����������� */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; /* ��� ��������������� */
    overflow: visible;
}

.qr-wrapper-container {
    position: relative;
    width: 250px; /* ������ ���������� */
    height: 250px;
    margin: 0 auto; /* ��������� ���������� */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* ����������� ���� */
    overflow: visible;
    padding: 5px; /* ������, ����� ������������� ����� �� �������� �� QR */
}

    /* ������������� ����� */
    .qr-wrapper-container::before,
    .qr-wrapper-container::after {
        content: '';
        position: absolute;
        width: 10px; /* ������ ����� */
        height: 10px;
        background: #007bff; /* ����� ���� */
        border-radius: 50%; /* ������ ����� �������� */
    }

    /* ������ ����� (�������� � ������� ����� ����) */
    .qr-wrapper-container::before {
        top: -5px;
        left: -5px;
        animation: dot-movement1 4s linear infinite; /* �������� �������� */
    }

    /* ������ ����� (�������� � ������ ������ ����) */
    .qr-wrapper-container::after {
        top: calc(100% - 5px);
        left: calc(100% - 5px);
        animation: dot-movement2 4s linear infinite; /* �������� �������� */
    }

/* �������� �������� ����� */
@keyframes dot-movement1 {
    0% {
        top: -5px;
        left: -5px; /* ������� ����� ���� */
    }

    25% {
        top: -5px;
        left: calc(100% - 5px); /* ������� ������ ���� */
    }

    50% {
        top: calc(100% - 5px); /* ������ ������ ���� */
        left: calc(100% - 5px);
    }

    75% {
        top: calc(100% - 5px);
        left: -5px; /* ������ ����� ���� */
    }

    100% {
        top: -5px;
        left: -5px; /* ����������� � ������� ����� ���� */
    }
}

@keyframes dot-movement2 {
    0% {
        top: calc(100% - 5px); /* ������ ������ ���� */
        left: calc(100% - 5px);
    }

    25% {
        top: calc(100% - 5px);
        left: -5px; /* ������ ����� ���� */
    }

    50% {
        top: -5px;
        left: -5px; /* ������� ����� ���� */
    }

    75% {
        top: -5px;
        left: calc(100% - 5px); /* ������� ������ ���� */
    }

    100% {
        top: calc(100% - 5px); /* ����������� � ������ ������ ���� */
        left: calc(100% - 5px);
    }
}

.qr-wrapper-container canvas {
    width: 100%;
    height: 100%;
}

.qr-modal-title {
    margin-top: 15px;
    font-size: 1rem;
    font-weight: bold;
    color: black; /* ׸���� ���� ������ */
}

.ticket-inactive {
    color: #aaa !important;
}

.reg-balance-description {
    font-size: 12px;
}

.spacer {
    height: 0; /* ��������� �������� */
    transition: height 0.3s ease; /* ������� ��������� ������ */
}
