.stepper-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 44px); /* Full screen height */
}

.stepper-header {
    color: black; /* Text color */
    margin-top: 10px; /* Top margin */
    margin-bottom: 10px; /* Bottom margin */
    text-align: center; /* Center text */
}

.stepper-header-text {
    font-weight: bold; /* Bold text */
    text-transform: uppercase; /* Uppercase text */
    font-size: 18px; /* Font size */
    margin: 0; /* No margin */
    padding: 0; /* No padding */
}

.stepper-stepper {
    display: flex; /* Inline layout for steps */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    margin-top: 20px;
    position: relative; /* For line placement */
    padding: 0!important;
    margin-bottom: 0!important;
}

.stepper-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
}

    .stepper-step:before {
        --size: clamp(1.5rem, 5vw, 3rem);
        content: "";
        position: relative;
        display: block;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        margin: 0 auto;
        border: 2px solid black;
    }

    .stepper-step.active:before {
        background: black;
        border: none;
    }

    .stepper-step:not(:last-child):after {
        --circle-size: clamp(1.5rem, 5vw, 3rem);
        content: "";
        position: relative;
        top: calc(var(--circle-size) / 2);
        width: calc(100% - var(--circle-size));
        left: 50%;
        height: 2px;
        background-color: black;
        order: -1;
    }

.stepper-circle {
    width: 25px; /* Reduced size */
    height: 25px; /* Reduced size */
    border-radius: 50%;
    background-color: transparent; /* No fill for inactive steps */
    border: 2px solid black; /* Black border */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}

.stepper-step.active .stepper-circle {
    width: 30px; /* Larger size for active step */
    height: 30px;
    background-color: black; /* Fill for active step */
    border: none; /* Remove border for active step */
}

.stepper-line {
    width: 50px; /* Line length */
    height: 2px; /* Line height */
    background-color: black; /* Line color */
}

.stepper-step-name {
    margin-top: 5px; /* Space above step name */
    font-weight: normal; /* Regular weight */
    text-align: center;
    font-size: 13px;
}

.stepper-step.active .stepper-step-name {
    font-weight: bold; /* Bold for active step name */
}

.stepper-empty-space {
    flex-grow: 1; /* Take up remaining space */
    display: flex;
    flex-direction: column; /* Column layout */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Padding around content */
}

.stepper-empty-space-top {
    flex-grow: 1; /* Take up remaining space */
    display: flex;
    flex-direction: column; /* Column layout */
    justify-content: start;
    align-items: center; /* Center content horizontally */
    padding: 20px; /* Padding around content */
    max-height: calc(100% - 174.5px);
}

.stepper-select-caption {
    font-weight: bold; /* Bold text */
    text-align: center; /* Center text */
    margin-bottom: 10px; /* Space below caption */
}

.stepper-select-wrapper {
    width: 100%; /* Ensure full width */
    max-width: 100%; /* No overflow */
    margin: 0 10px; /* Margin from screen borders */
}

.stepper-select {
    width: 100%; /* Full width for select */
    padding: 10px; /* Padding inside select */
    border: 1px solid black; /* Border */
}

.stepper-info-text {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    text-align: center;
}

.stepper-payment-link {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 18px;
}

.stepper-payment-instructions {
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
    padding: 10px 10px 0 10px;
    text-align: left;
    line-height: 18px;
    border-top: 1px solid #ccc;
}
    .stepper-payment-instructions.no-top {
        border-top: none;
        padding-top: 0;
    }
    .stepper-payment-instructions p {
        margin-bottom: 5px !important;
    }

.stepper-info-table {
    border-collapse: collapse; /* Merge table borders */
    width: 100%; /* Full width */
}

    .stepper-info-table td {
        border: 1px solid black; /* Border for table cells */
        padding: 10px; /* Padding inside cells */
        text-align: center; /* Center text inside cells */
    }

.stepper-table-caption {
    font-weight: bold; /* Bold text */
    text-align: center; /* Center align text */
    margin-bottom: 10px; /* Space below caption */
}

.stepper-button-container {
    display: flex; /* Use flexbox for button alignment */
    justify-content: center; /* Center buttons horizontally */
    gap: 10px; /* Space between buttons */
    margin-bottom: 20px; /* Space from bottom */
    width: 100%; /* Full width */
}

.stepper-button-container-nomargin {
    display: flex; /* Use flexbox for button alignment */
    justify-content: center; /* Center buttons horizontally */
    gap: 10px; /* Space between buttons */
    margin-bottom: 5px; /* Space from bottom */
    width: 100%; /* Full width */
}

.stepper-button {
    background-color: rgb(25, 135, 84);
    color: white;
    border: none;
    line-height: 24px;
    border-radius: 6px;
    padding: 10px; /* Smaller padding */
    font-size: 16px;
    cursor: pointer;
    flex-grow: 1; /* Allow "Next" button to take remaining width */
}

.stepper-button:disabled {
    opacity: 0.65;
}

.stepper-small-button {
    background-color: rgb(25, 135, 84);
    color: white;
    border: none;
    line-height: 24px;
    border-radius: 6px;
    padding: 10px; /* Smaller padding */
    font-size: 16px;
    cursor: pointer;
    display: flex; /* Center the icon */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 40px; /* Fixed width for small button */
}

.stepper-terms {
    text-align: center;
    font-size: 13px;
}

    .stepper-terms a, .stepper-terms a:visited {
        text-decoration: none;
        color: #047ff9;
    }